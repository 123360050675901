var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Transition", { attrs: { name: "modal" } }, [
    _c(
      "aside",
      {
        staticClass:
          "backdrop position-fixed d-flex justify-content-center z-index-1032",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "Modal m-auto position-relative AlertComponent__layout",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "emobg-background-color-white full-min-height emobg-border-radius-large overflow-hidden",
              },
              [
                _c(
                  "main",
                  { staticClass: "px-3 py-5" },
                  [_vm._t("default")],
                  2
                ),
                _c(
                  "footer",
                  {
                    staticClass:
                      "Modal__footer emobg-border-top-1 emobg-border-color-ground-light px-5 py-3 emobg-background-color-ground-lightest",
                  },
                  [
                    _c("ModalActions", {
                      attrs: {
                        "data-test-id": "modal-actions",
                        "is-loading": _vm.isLoading,
                        "action-label": _vm.actionLabel,
                      },
                      on: { action: _vm.onAction, cancel: _vm.onCancel },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }